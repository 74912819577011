import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { getConnection } from 'connection'
import { ConnectionType } from 'connection/types'
import { WalletConnectV2 } from 'connection/WalletConnectV2'
import { L1_CHAIN_IDS, L2_CHAIN_IDS } from 'constants/chains'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { Box } from 'nft/components/Box'
import { Portal } from 'nft/components/common/Portal'
import { Column, Row } from 'nft/components/Flex'
import { useIsMobile } from 'nft/hooks'
import { useRef, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import styled, { useTheme } from 'styled-components'
import { getSupportedChainIdsFromWalletConnectSession } from 'utils/getSupportedChainIdsFromWalletConnectSession'

import { ReactComponent as OrbiterSvg } from '../../assets/svg/orbiter.svg'
import { ReactComponent as RhinoSvg } from '../../assets/svg/rhino.svg'
import { ReactComponent as ScrollSvg } from '../../assets/svg/scroll.svg'
import Symbiosis from '../../assets/svg/symbiosis.png'
import * as styles from './ChainSelector.css'
import { NavDropdown } from './NavDropdown'
const NETWORK_SELECTOR_CHAINS = [...L1_CHAIN_IDS, ...L2_CHAIN_IDS]

interface ChainSelectorProps {
  leftAlign?: boolean
  wrapperClassName?: string
}

function useWalletSupportedChains(): ChainId[] {
  const { connector } = useWeb3React()
  const connectionType = getConnection(connector).type

  switch (connectionType) {
    case ConnectionType.WALLET_CONNECT_V2:
    case ConnectionType.UNISWAP_WALLET_V2:
      return getSupportedChainIdsFromWalletConnectSession((connector as WalletConnectV2).provider?.session)
    default:
      return NETWORK_SELECTOR_CHAINS
  }
}
const Label = styled.div`
  grid-column: 2;
  grid-row: 1;
  font-size: 16px;
  font-weight: 485;
`
const AJump = styled.a`
  text-decoration: none;
  display: flex;
`
const Container = styled.button<{ disabled?: boolean }>`
  align-items: center;
  background: none;
  border: none;
  border-radius: 12px;
  color: ${({ theme }) => theme.neutral1};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  justify-content: space-between;
  line-height: 20px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  padding: 10px 8px;
  text-align: left;
  transition: ${({ theme }) => theme.transition.duration.medium} ${({ theme }) => theme.transition.timing.ease}
    background-color;
  white-space: nowrap;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 100%;
  }

  &:hover {
    background-color: ${({ disabled, theme }) => (disabled ? 'none' : theme.surface3)};
  }
`

export const BridgeDropdown = ({ leftAlign, wrapperClassName = '' }: ChainSelectorProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const isMobile = useIsMobile()

  const theme = useTheme()

  const ref = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef])

  const dropdown = (
    <NavDropdown top="56" left="0" right={leftAlign ? 'auto' : '0'} ref={modalRef}>
      <Column paddingX="8" data-testid="chain-selector-options">
        <AJump href="https://scroll.io/bridge" target="_blank">
          <Container>
            <div style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }}>
              <ScrollSvg></ScrollSvg>
            </div>
            <Label>Scroll Bridge</Label>
          </Container>
        </AJump>
        <AJump href="https://www.orbiter.finance/?source=Ethereum&dest=Scroll&token=ETH" target="_blank">
          <Container>
            <div style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }}>
              <OrbiterSvg style={{ width: '20px' }}></OrbiterSvg>
            </div>
            <Label>Orbiter Bridge</Label>
          </Container>
        </AJump>
        <AJump href="https://app.rhino.fi/bridge" target="_blank">
          <Container>
            <div style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }}>
              <RhinoSvg></RhinoSvg>
            </div>
            <Label>Rhino Bridge</Label>
          </Container>
        </AJump>
        <AJump
          href="https://app.symbiosis.finance/swap?chainIn=Ethereum&chainOut=Scroll&tokenIn=ETH&tokenOut=ETH"
          target="_blank"
        >
          <Container>
            <img style={{ marginRight: '5px' }} width={22} height={15} src={Symbiosis}></img>
            <Label>Symbiosis</Label>
          </Container>
        </AJump>

        {/* <AJump href="https://owlto.finance/bridge" target="_blank">
          <Container>
            <div style={{ marginRight: '5px' }}>
              <OwltoSvg></OwltoSvg>
            </div>
            <Label>Owlto Bridge</Label>
          </Container>
        </AJump> */}
      </Column>
    </NavDropdown>
  )

  const chevronProps = {
    height: 20,
    width: 20,
    color: theme.neutral2,
  }

  return (
    <Box position="relative" ref={ref}>
      <Row
        data-testid="chain-selector"
        as="button"
        gap="8"
        className={styles.ChainSelector}
        background={isOpen ? 'accent2' : 'none'}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={wrapperClassName}>
          Bridge
          {isOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />}
        </span>
      </Row>
      {isOpen && (isMobile ? <Portal>{dropdown}</Portal> : <>{dropdown}</>)}
    </Box>
  )
}
