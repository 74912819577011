import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { ChainId } from '@uniswap/sdk-core'

import store from '../../state/index'

const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [ChainId.SCROLL]: 'https://api.studio.thegraph.com/query/55584/v3_scroll/version/latest',
  [ChainId.SCROLL_SEPOLIA]: 'https://api.studio.thegraph.com/query/55584/v3_scroll_sepolia/version/latest',
}

const CHAIN_BLOCK_SUBGRAPH_URL: Record<number, string> = {
  [ChainId.SCROLL]: 'https://api.studio.thegraph.com/query/55584/scroll_block/version/latest',
  [ChainId.SCROLL_SEPOLIA]: 'https://api.studio.thegraph.com/query/55584/scroll_sepolia_blocks/version/latest',
}

const httpLink = new HttpLink({ uri: CHAIN_SUBGRAPH_URL[ChainId.SCROLL] })

// This middleware will allow us to dynamically update the uri for the requests based off chainId
// For more information: https://www.apollographql.com/docs/react/networking/advanced-http-networking/
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const chainId = store.getState().application.chainId

  operation.setContext(() => ({
    uri: chainId && CHAIN_SUBGRAPH_URL[chainId] ? CHAIN_SUBGRAPH_URL[chainId] : CHAIN_SUBGRAPH_URL[ChainId.SCROLL],
  }))

  return forward(operation)
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})

export const chainToApolloClient: Record<number, ApolloClient<NormalizedCacheObject>> = {
  [ChainId.SCROLL]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.SCROLL],
  }),
  [ChainId.SCROLL_SEPOLIA]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.SCROLL_SEPOLIA],
  }),
}

export const chainToApolloBlockClient: Record<number, ApolloClient<NormalizedCacheObject>> = {
  [ChainId.SCROLL]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.SCROLL],
    cache: new InMemoryCache(),
  }),
  [ChainId.SCROLL_SEPOLIA]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.SCROLL_SEPOLIA],
    cache: new InMemoryCache(),
  }),
}
